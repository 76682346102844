import React from "react";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg"
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png"
import tushar from "./assests/tushar.png"
import rajat from "./assests/rajat.jpg"
import shield from "./assests/shield.svg"
import networkLines from "./assests/networkLines.svg"
import brandLogo from "./assests/brand-logo-dk.svg"
import certified from "./assests/certified.svg"
import linkedin from "./assests/linkedin.svg"
import twitter from "./assests/twitter.svg"
import youtube from "./assests/youtube.svg"
import heroBrands from "./assests/heroBrands.svg"
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg"
import reportImg from "./assests/report-img.png";
// import "./servicesStyle.css";
import "../styleguide.css";
import vector from "./assests/vector.svg";

export default function DarkEye() {
    return (
        <div className="darkeye-website-layer">
            <div className="darkeye-website-v-layer">
                <div className="darkeye-main-wrapper">
                    <div className="darkeye-navbar2">
                        <div className="darkeye-brand-logo-lt" />

                        <div className="darkeye-navlinks">
                            <div className="darkeye-links">Services</div>

                            <div className="darkeye-links">Solutions</div>

                            <div className="darkeye-links">Process</div>

                            <div className="darkeye-links">Reports</div>
                        </div>

                        <div className="darkeye-nav-btn2">
                            <div className="darkeye-text-wrapper-42">Secure Now</div>
                        </div>
                    </div>
                    <div className="darkeye-darkeye-hero-section">
                        <div className="darkeye-hero-section-2">
                            <div className="darkeye-hero-header">
                                <p className="darkeye-defend-against-cyber">
                                    <span className="darkeye-span">Dark eye Watcher </span>


                                </p>

                                <p className="darkeye-p">
                                    Providing actionable insights and detailed reports on potential data breaches over dark and deep web
                </p>
                            </div>

                            <div>
                                <div className="darkeye-download-btn">
                                    <div className="darkeye-text-wrapper-4">Download Free Report</div>
                                </div>
                                <div className="darkeye-div-wrapper">
                                    <div className="darkeye-text-wrapper-6">Get Quote</div>
                                </div>
                            </div>



                            <div className="darkeye-trust">
                                <div className="darkeye-overall-rating">
                                    <div className="darkeye-frame">
                                        <img className="darkeye-img" alt="overallRating" src={overallRating} />
                                    </div>
                                </div>

                                <div className="darkeye-frame-2">
                                    <img
                                        className="darkeye-img-2"
                                        alt="Img"
                                        src={heroBrands}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="darkeye-services-section2">
                        <div className="darkeye-div-2">
                            <button className="darkeye-primary-btn-instance2"
                                divclassName="darkeye-design-component-instance-node"
                                property1="tag" >ApniSec / Dark Eye Watcher</button>
                            <div className="darkeye-section1-text">Monitor and secure your organization against threats emerging from the dark web. With advanced intelligence to Filter Valid Working Credentials and Leaks  </div>
                        </div>
                    </div>



                    <div className="darkeye-report-section2">
                        <div className="darkeye-section2-heading">Unveiling the Hidden Risks on the Dark Web</div>
                        <div className="darkeye-report-component131">
                            <div className="darkeye-section-1">
                                <div className="darkeye-content-1">
                                    <div className="darkeye-text-wrapper-131">Compromised Data</div>

                                    <p className="darkeye-comprehensive131">
                                        Corporate Devices Compromised via Breaches, published On the dark Web & Private forums
                    </p>
                                </div>


                            </div>

                            <div className="darkeye-section-2">
                                <div className="darkeye-content-1">
                                    <div className="darkeye-text-wrapper-131">Emerging Threats</div>

                                    <p className="darkeye-comprehensive131">
                                        Identifying new threats targeted at your business financial or Business impact.
                    </p>
                                </div>


                            </div>
                            <div className="darkeye-section-3">
                                <div className="darkeye-content-1">
                                    <div className="darkeye-text-wrapper-131">Brand Mentions</div>

                                    <p className="darkeye-comprehensive131">
                                        Misuse of your brand name or trademarks in illicit activities.
                    </p>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="darkeye-report-section3">
                        <div className="darkeye-section3-heading">Comprehensive Protection at Your Fingertips</div>
                        <div className="darkeye-report-component131">
                            <div className="darkeye-section-1">
                                <div className="darkeye-content-1">
                                    <img className="darkeye-socialIcon132" alt="Twitter" src={twitter} />
                                    <div className="darkeye-text-wrapper-132">Threat Intelligence</div>
                                    <p className="darkeye-comprehensive132">
                                        Continuous surveillance on deep web with scraping organization keywords
                                     </p>
                                </div>
                                <div className="darkeye-content-1">
                                    <img className="darkeye-socialIcon132" alt="Twitter" src={twitter} />
                                    <div className="darkeye-text-wrapper-132">Threat Intelligence</div>
                                    <p className="darkeye-comprehensive132">
                                        Continuous surveillance on deep web with scraping organization keywords
                                     </p>
                                </div>
                            </div>

                            <div className="darkeye-section-2">
                                <div className="darkeye-content-1">
                                    <div className="darkeye-text-wrapper-132">AI-Powered Alerts</div>
                                    <p className="darkeye-comprehensive132">
                                        Identifying new threats targeted at your business financial or Business impact.
                                      </p>
                                </div>
                                <div className="darkeye-content-1">
                                    <img className="darkeye-socialIcon132" alt="Twitter" src={twitter} />
                                    <div className="darkeye-text-wrapper-132">Threat Intelligence</div>
                                    <p className="darkeye-comprehensive132">
                                        Continuous surveillance on deep web with scraping organization keywords
                                     </p>
                                </div>

                            </div>
                            <div className="darkeye-section-3">
                                <div className="darkeye-content-1">
                                    <div className="darkeye-text-wrapper-132">Leaks Classification</div>
                                    <p className="darkeye-comprehensive132">
                                        Misuse of your brand name or trademarks in illicit activities.
                                     </p>
                                </div>
                                <div className="darkeye-content-1">
                                    <img className="darkeye-socialIcon132" alt="Twitter" src={twitter} />
                                    <div className="darkeye-text-wrapper-132">Threat Intelligence</div>
                                    <p className="darkeye-comprehensive132">
                                        Continuous surveillance on deep web with scraping organization keywords
                                     </p>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div className="darkeye-stats-section-1">
                        <div className="darkeye-div-3">
                            <div className="darkeye-frame-15">
                                <div className="darkeye-text-wrapper-141">3Bn+</div>

                                <div className="darkeye-text-wrapper-301">Records Scraped</div>
                            </div>

                            <div className="darkeye-frame-15">
                                <div className="darkeye-element">15K+</div>

                                <div className="darkeye-text-wrapper-301">Assets Monitored</div>
                            </div>

                            <div className="darkeye-frame-15">
                                <div className="darkeye-text-wrapper-141">200TB+</div>

                                <div className="darkeye-text-wrapper-301">Data Analysed</div>
                            </div>

                            <div className="darkeye-frame-15">
                                <div className="darkeye-text-wrapper-141">99.99%</div>

                                <div className="darkeye-text-wrapper-301">Threat Mitigation</div>
                            </div>
                        </div>
                    </div>

                    <div className="darkeye-how-we-do-it-section">
                        <div className="darkeye-title-2">
                            <div className="darkeye-overlap-group-6">
                                <img className="darkeye-line-5" alt="Line" src={line74} />

                                <div className="darkeye-how-we-do-it">How We Do It</div>
                            </div>
                        </div>

                        <div className="darkeye-w-rapper-wrapper">
                            <div className="darkeye-w-rapper">
                                <div className="darkeye-overlap-6">
                                    <div className="darkeye-frame-16">
                                        <div className="darkeye-content-2">
                                            <img className="darkeye-img" alt="Frame" src={frame276} />

                                            <div className="darkeye-text-wrapper-31">
                                                Identify Critical Assets
                      </div>

                                            <p className="darkeye-text-wrapper-32">
                                                Primary Customer And Internet Facing Applications
                      </p>
                                        </div>

                                        <div className="darkeye-content-3">
                                            <div className="darkeye-img-wrapper">
                                                <img className="darkeye-watcher" alt="Watcher" src={frame276} />
                                            </div>

                                            <div className="darkeye-text-wrapper-31">Watcher Onboarding</div>

                                            <p className="darkeye-text-wrapper-32">
                                                Asset Monitoring, Scm, Dark Eye Watcher For Overall
                                                Monitoring
                      </p>
                                        </div>

                                        <div className="darkeye-content-4">
                                            <div className="darkeye-img-wrapper">
                                                <img className="darkeye-img-3" alt="Report" src={frame276} />
                                            </div>

                                            <div className="darkeye-text-wrapper-31">
                                                Reporting And Mitigation
                      </div>

                                            <p className="darkeye-text-wrapper-32">
                                                Regular Reporting Patching Re-testing Patches With Idle
                                                Fixes
                      </p>
                                        </div>
                                    </div>

                                    <div className="darkeye-frame-17">
                                        <div className="darkeye-content-5">
                                            <div className="darkeye-img-wrapper">
                                                <img
                                                    className="darkeye-img-3"
                                                    alt="Penetration testing"
                                                    src={frame276}
                                                />
                                            </div>

                                            <div className="darkeye-text-wrapper-33">
                                                Vulnerability Assessment
                      </div>

                                            <p className="darkeye-text-wrapper-32">
                                                Security Testing By Expertise Team Of Certified Hackers
                      </p>
                                        </div>

                                        <div className="darkeye-content-6">
                                            <div className="darkeye-img-wrapper">
                                                <img
                                                    className="darkeye-img-3"
                                                    alt="Blockchain"
                                                    src={frame276}
                                                />
                                            </div>

                                            <div className="darkeye-text-wrapper-31">vCISO</div>

                                            <p className="darkeye-text-wrapper-32">
                                                Acting As A Security Team With Regular Threat Modelling
                                                And Architecture Reviews
                      </p>
                                        </div>
                                    </div>

                                    <div className="darkeye-group-13">
                                        <div className="darkeye-overlap-6">
                                            <div className="darkeye-lines">
                                                <div className="darkeye-overlap-group-7">
                                                    <img className="darkeye-line-6" alt="Line" src={line79} />

                                                    <img className="darkeye-line-7" alt="Line" src={line81} />

                                                    <img className="darkeye-line-8" alt="Line" src={line83} />
                                                </div>

                                                <img className="darkeye-line-9" alt="Line" src={line80} />

                                                <img className="darkeye-line-10" alt="Line" src={line82} />

                                                <img className="darkeye-line-11" alt="Line" src={line84} />
                                            </div>

                                            <div className="darkeye-point">
                                                <div className="darkeye-timelineDot" />

                                                <div className="darkeye-timelineDot" />

                                                <div className="darkeye-timelineDot" />

                                                <div className="darkeye-timelineDot" />

                                                <div className="darkeye-timelineDot" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="darkeye-about-section">
                        <div className="darkeye-about-component">
                            <div className="darkeye-col">
                                <div className="darkeye-div-5">
                                    <div className="darkeye-text-wrapper-34">Our Mission</div>

                                    <p className="darkeye-comprehensive">
                                        To make Digital Space A safer Place to be
                  </p>

                                    <div className="darkeye-frame-18">
                                        <div className="darkeye-frame-19">
                                            <div className="darkeye-frame-6">
                                                <div className="darkeye-group-14" />
                                                <div className="darkeye-bullet-point">
                                                    Persistent surveillance
                        </div>
                                            </div>
                                        </div>

                                        <div className="darkeye-frame-19">
                                            <div className="darkeye-frame-6">
                                                <div className="darkeye-group-14" />
                                                <div className="darkeye-bullet-point">
                                                    Brand reputation Security
                        </div>
                                            </div>
                                        </div>

                                        <div className="darkeye-frame-19">
                                            <div className="darkeye-frame-6">
                                                <div className="darkeye-group-14" />
                                                <div className="darkeye-bullet-point">
                                                    InHouse security Team
                        </div>
                                            </div>
                                        </div>

                                        <div className="darkeye-frame-19">
                                            <div className="darkeye-frame-6">
                                                <div className="darkeye-group-14" />
                                                <div className="darkeye-bullet-point">
                                                    Proactive instead of Reactive
                        </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <p className="darkeye-our-expert-team-of">
                                    Our Expert Team <br />
                  of Hacker
                </p>
                            </div>

                            <div className="darkeye-col-2">
                                <div className="darkeye-div-4">
                                    <div className="darkeye-text-wrapper-36">Why Choose Us</div>

                                    <div className="darkeye-USP">
                                        <div className="darkeye-frame-20">
                                            <img
                                                className="darkeye-trustIcon"
                                                alt="trustIcon"
                                                src={databaseIcon}
                                            />

                                            <div className="darkeye-text-wrapper-37">Data Loss Prevented</div>
                                        </div>

                                        <div className="darkeye-frame-20">
                                            <img
                                                className="darkeye-trustIcon"
                                                alt="trustIcon"
                                                src={databaseIcon}
                                            />

                                            <div className="darkeye-text-wrapper-37">Third Party Monitoring </div>
                                        </div>

                                        <div className="darkeye-frame-20">
                                            <img
                                                className="darkeye-trustIcon"
                                                alt="trustIcon"
                                                src={databaseIcon}
                                            />

                                            <div className="darkeye-text-wrapper-37">Trusted</div>
                                        </div>

                                        <div className="darkeye-frame-20">
                                            <img
                                                className="darkeye-trustIcon"
                                                alt="trustIcon"
                                                src={databaseIcon}
                                            />

                                            <div className="darkeye-text-wrapper-37">Trusted</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="darkeye-certificates">

                                    <img
                                        className="darkeye-certificateIcon"
                                        alt="Screenshot"
                                        src={certificates}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="darkeye-our-mission">
                        <div className="darkeye-about-mission-component">
                            <div className="darkeye-col">
                                <div className="darkeye-div-5">
                                    <img
                                        className="darkeye-shieldIcon"
                                        alt="shieldIcon"
                                        src={shield}
                                    />
                                </div>
                                <div>
                                    <p className="darkeye-our-secured-brands">
                                        Our Secured<br />
                  Brands
                </p>
                                    <p className="darkeye-comprehensive-2">
                                        Retained 100% Customers since inception.  <br /> 100% perfect customer retention rate
                </p>
                                </div>
                            </div>

                            <div className="darkeye-col-2">
                                <div className="darkeye-certificates">
                                    <img
                                        className="darkeye-trustedBrands"
                                        alt="Screenshot"
                                        src={trustedBrands}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="darkeye-testimonial-section">
                        <div className="darkeye-title-4">
                            <div className="darkeye-overlap-group-8">
                                <img className="darkeye-line-13" alt="Line" src={line69} />

                                <p className="darkeye-text-wrapper-8">
                                    Our Secured Client’s <br />
                  says It All
                </p>
                            </div>
                        </div>

                        <div className="darkeye-testimonial-wrap">
                            <div className="darkeye-testimonial-card">
                                <div className="darkeye-div-5">
                                    <p className="darkeye-comprehensive-2">
                                        Comprehensive Evaluations To Identify Vulnerabilities And
                                        Provide Actionable Insights For Risk Mitigation.
                  </p>
                                </div>

                                <div className="darkeye-frame-28">
                                    <img
                                        className="darkeye-IMG-wa"
                                        alt="Img"
                                        src={databaseIcon}
                                    />

                                    <div className="darkeye-frame-29">
                                        <div className="darkeye-text-wrapper-11">Xyz Company</div>

                                        <div className="darkeye-text-wrapper-38">Co-founder</div>
                                    </div>
                                </div>
                            </div>

                            <div className="darkeye-testimonial-card-2">
                                <div className="darkeye-div-5">
                                    <p className="darkeye-comprehensive-2">
                                        Comprehensive Evaluations To Identify Vulnerabilities And
                                        Provide Actionable Insights For Risk Mitigation.
                  </p>
                                </div>

                                <div className="darkeye-frame-28">
                                    <img
                                        className="darkeye-IMG-2"
                                        alt="Img"
                                        src={databaseIcon}
                                    />

                                    <div className="darkeye-frame-29">
                                        <div className="darkeye-text-wrapper-11">Xyz Company</div>

                                        <div className="darkeye-text-wrapper-38">Co-founder</div>
                                    </div>
                                </div>
                            </div>

                            <div className="darkeye-testimonial-card-3">
                                <div className="darkeye-div-5">
                                    <p className="darkeye-comprehensive-2">
                                        Comprehensive Evaluations To Identify Vulnerabilities And
                                        Provide Actionable Insights For Risk Mitigation.
                  </p>
                                </div>

                                <div className="darkeye-frame-28">
                                    <img
                                        className="darkeye-IMG-2"
                                        alt="Img"
                                        src={databaseIcon}
                                    />

                                    <div className="darkeye-frame-29">
                                        <div className="darkeye-text-wrapper-11">Xyz Company</div>

                                        <div className="darkeye-text-wrapper-38">Co-founder</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="darkeye-CTA-section">
                        {/* <div className="darkeye-background" /> */}
                        <div className="darkeye-networkLinesWrapper">  <img
                            className="darkeye-networkLines"
                            alt="Vector"
                            src={networkLines}
                        /></div>

                        <div className="darkeye-div-2">
                            <div className="darkeye-text-wrapper-40">Protect Your Data Now</div>
                        </div>
                        <div className="darkeye-div-wrapper">
                            <div className="darkeye-text-wrapper-6">Get Quote</div>
                        </div>
                        <div className="darkeye-networkLinesWrapper1">  <img
                            className="darkeye-networkLines1"
                            alt="Vector"
                            src={networkLines}
                        /></div>
                    </div>

                    <footer className="darkeye-footer">
                        <div className="darkeye-frame-33">
                            <div className="darkeye-brand-logo-dk" >
                                <img src={brandLogo} />
                                <img src={certified} />
                                <div className="darkeye-rights-reserved">
                                    © 2024 ApniSec. All rights reserved.
                </div>
                            </div>

                            <div className="darkeye-frame-34">
                                <div className="darkeye-social-icon">
                                    <img className="darkeye-socialIcon" alt="Youtube" src={linkedin} />

                                    <img className="darkeye-line-70" alt="Line" src={line70} />

                                    <img className="darkeye-socialIcon" alt="Twitter" src={twitter} />

                                    <img className="darkeye-line-70" alt="Line" src={line70} />

                                    <img className="darkeye-socialIcon" alt="Twitter" src={youtube} />
                                </div>

                                <div className="darkeye-flinks">
                                    <div className="darkeye-frame-35">
                                        <div className="darkeye-text-wrapper-45">Company</div>

                                        <div className="darkeye-frame-36">
                                            <div className="darkeye-flink-options">Home</div>

                                            <div className="darkeye-text-wrapper-32">Solutions</div>

                                            <div className="darkeye-text-wrapper-32">Process</div>

                                            <div className="darkeye-text-wrapper-32">Report</div>

                                            <div className="darkeye-text-wrapper-32">Services</div>
                                        </div>
                                    </div>

                                    <div className="darkeye-frame-35">
                                        <div className="darkeye-text-wrapper-45">Services</div>

                                        <div className="darkeye-frame-36">
                                            <div className="darkeye-flink-options">Dark Eye Watcher</div>

                                            <div className="darkeye-text-wrapper-32">Cloud Security</div>

                                            <div className="darkeye-text-wrapper-32">Virtual CISO</div>

                                            <div className="darkeye-text-wrapper-32">Red Team Assesment</div>

                                            <div className="darkeye-text-wrapper-32">VAPT</div>
                                        </div>
                                    </div>
                                </div>


                                <div className="darkeye-privacy-policy-terms">
                                    Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms And Services
                </div>
                            </div>
                        </div>


                    </footer>

                </div>
            </div>
        </div>
    );
};
