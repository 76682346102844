import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import DarkEye from "./pages/DarkEye";
import VCiso from "./pages/VCiso";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/darkeye" element={<DarkEye />} />
      <Route path="/vciso" element={<VCiso />} />
    </Routes>
  );
}

export default App;
