import React from "react";
import frame276 from "./assests/frame-276.svg";
import overallRating from "./assests/overallRating.svg";
import certificates from "./assests/certificates.svg";
import trustedBrands from "./assests/trustedBrands.svg"
import databaseIcon from "./assests/databaseIcon.svg";
import hardik from "./assests/hardik.png"
import tushar from "./assests/tushar.png"
import rajat from "./assests/rajat.jpg"
import shield from "./assests/shield.svg"
import networkLines from "./assests/networkLines.svg"
import brandLogo from "./assests/brand-logo-dk.svg"
import certified from "./assests/certified.svg"
import linkedin from "./assests/linkedin.svg"
import twitter from "./assests/twitter.svg"
import youtube from "./assests/youtube.svg"
import heroBrands from "./assests/heroBrands.svg"
import line69 from "./assests/line-69.svg";
import line73 from "./assests/line-73.svg";
import line74 from "./assests/line-74.svg";
import line79 from "./assests/line-79.svg";
import line80 from "./assests/line-80.svg";
import line81 from "./assests/line-81.svg";
import line82 from "./assests/line-82.svg";
import line83 from "./assests/line-83.svg";
import line84 from "./assests/line-84.svg";
import line70 from "./assests/line70.svg"
import reportImg from "./assests/report-img.png";
import "./servicesStyle.css";
import "../styleguide.css";
import vector from "./assests/vector.svg";

export default function VCiso() {
  return (
    <div className="website-layer">
      <div className="website-v-layer">
        <div className="main-wrapper">
          <div className="navbar">
            <div className="brand-logo-lt" />

            <div className="navlinks">
              <div className="links">Services</div>

              <div className="links">Solutions</div>

              <div className="links">Process</div>

              <div className="links">Reports</div>
            </div>

            <div className="nav-btn">
              <div className="text-wrapper-4">Secure Now</div>
            </div>
          </div>
          <div className="darkeye-hero-section">
            <div className="hero-section-2">
              <div className="hero-header">
                <p className="defend-against-cyber">
                  <span className="span">Virtual CISO </span>

               
                </p>

                <p className="p">
                Build DevSecOps culture with InHouse Security Team
                </p>
              </div>

              <div>
               
                <div className="div-wrapper">
                  <div className="text-wrapper-6">Start Monitoring Now</div>
                </div>
              </div>



              <div className="trust">
                <div className="overall-rating">
                  <div className="frame">
                    <img className="img" alt="overallRating" src={overallRating} />
                  </div>
                </div>

                <div className="frame-2">
                  <img
                    className="img-2"
                    alt="Img"
                    src={heroBrands}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="services-section2">
            <div className="div-2">
              <button className="primary-btn-instance"
                divClassName="design-component-instance-node"
                property1="tag" >ApniSec / Dark Eye Watcher</button>
                <div className="section1-text">Monitor and secure your organization against threats emerging from the dark web. With advanced intelligence to Filter Valid Working Credentials and Leaks  </div>
            </div>
          </div>

          <div className="report-section">
            <div className="report-component">
              <div className="section-left"> <img className="report-img" alt="Report img" src={reportImg} />
                <div className="content-left">
                      <div className="text-wrapper-13">Sample Reports</div>

                      <p className="comprehensive">
                      Comprehensive Test cases & Reports to identify Loopholes and provide actionable insights for risk mitigation.
                    </p>
                    </div>

                    <div className="section-div-wrapper">
                  <div className="text-wrapper-section">Free Dark Web Report</div>
                </div>
              </div>
              <div className="section-right"><img className="report-img" alt="Report img" src={reportImg} />
                <div className="content-right">
                      <div className="text-wrapper-131">Customized Sample Report</div>

                      <p className="comprehensive">
                      Comprehensive Test cases & Reports to identify Loopholes and provide actionable insights for risk mitigation.
                    </p>
                    </div>

                    <div className="section-div-wrapper">
                  <div className="text-wrapper-section">Free Dark Web Report</div>
                </div></div>


            </div>
          </div>

          <div className="stats-section">
            <div className="div-3">
              <div className="header">
                <div className="div-4">
                  <div className="group-3">
                    <div className="overlap-group-4">
                      <img className="line-2" alt="Line" src={line73} />

                      <p className="text-wrapper-8">
                        Number Don’t Lie
                        <br />
                        data Speaks For Itself, Make
                        <br />
                        informed Decisions
                      </p>
                    </div>
                  </div>

                  <p className="all-in-one-cyber">
                    All In One Cyber Defence Platform
                  </p>
                </div>

                <div className="frame-11">
                  <div className="text-wrapper-14">849M+</div>

                  <div className="lines-of-code">Lines Of Code Reviewed</div>
                </div>
              </div>

              <div className="frame-12">
              </div>
            </div>

            <div className="div-3">
              <div className="frame-15">
                <div className="text-wrapper-14">3Bn+</div>

                <div className="text-wrapper-30">Records Scraped</div>
              </div>

              <div className="frame-15">
                <div className="element">15K+</div>

                <div className="text-wrapper-30">Assets Monitored</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-14">200TB+</div>

                <div className="text-wrapper-30">Data Analysed</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-14">99.99%</div>

                <div className="text-wrapper-30">Threat Mitigation</div>
              </div>
            </div>
          </div>

          <div className="how-we-do-it-section">
            <div className="title-2">
              <div className="overlap-group-6">
                <img className="line-5" alt="Line" src={line74} />

                <div className="how-we-do-it">How We Do It</div>
              </div>
            </div>

            <div className="w-rapper-wrapper">
              <div className="w-rapper">
                <div className="overlap-6">
                  <div className="frame-16">
                    <div className="content-2">
                      <img className="img" alt="Frame" src={frame276} />

                      <div className="text-wrapper-31">
                        Identify Critical Assets
                      </div>

                      <p className="text-wrapper-32">
                        Primary Customer And Internet Facing Applications
                      </p>
                    </div>

                    <div className="content-3">
                      <div className="img-wrapper">
                        <img className="watcher" alt="Watcher" src={frame276} />
                      </div>

                      <div className="text-wrapper-31">Watcher Onboarding</div>

                      <p className="text-wrapper-32">
                        Asset Monitoring, Scm, Dark Eye Watcher For Overall
                        Monitoring
                      </p>
                    </div>

                    <div className="content-4">
                      <div className="img-wrapper">
                        <img className="img-3" alt="Report" src={frame276} />
                      </div>

                      <div className="text-wrapper-31">
                        Reporting And Mitigation
                      </div>

                      <p className="text-wrapper-32">
                        Regular Reporting Patching Re-testing Patches With Idle
                        Fixes
                      </p>
                    </div>
                  </div>

                  <div className="frame-17">
                    <div className="content-5">
                      <div className="img-wrapper">
                        <img
                          className="img-3"
                          alt="Penetration testing"
                          src={frame276}
                        />
                      </div>

                      <div className="text-wrapper-33">
                        Vulnerability Assessment
                      </div>

                      <p className="text-wrapper-32">
                        Security Testing By Expertise Team Of Certified Hackers
                      </p>
                    </div>

                    <div className="content-6">
                      <div className="img-wrapper">
                        <img
                          className="img-3"
                          alt="Blockchain"
                          src={frame276}
                        />
                      </div>

                      <div className="text-wrapper-31">vCISO</div>

                      <p className="text-wrapper-32">
                        Acting As A Security Team With Regular Threat Modelling
                        And Architecture Reviews
                      </p>
                    </div>
                  </div>

                  <div className="group-13">
                    <div className="overlap-6">
                      <div className="lines">
                        <div className="overlap-group-7">
                          <img className="line-6" alt="Line" src={line79} />

                          <img className="line-7" alt="Line" src={line81} />

                          <img className="line-8" alt="Line" src={line83} />
                        </div>

                        <img className="line-9" alt="Line" src={line80} />

                        <img className="line-10" alt="Line" src={line82} />

                        <img className="line-11" alt="Line" src={line84} />
                      </div>

                      <div className="point">
                        <div className="timelineDot" />

                        <div className="timelineDot" />

                        <div className="timelineDot" />

                        <div className="timelineDot" />

                        <div className="timelineDot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about-section">
            <div className="about-component">
              <div className="col">
                <div className="div-5">
                  <div className="text-wrapper-34">Our Mission</div>

                  <p className="comprehensive">
                    To make Digital Space A safer Place to be
                  </p>

                  <div className="frame-18">
                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          Persistent surveillance
                        </div>
                      </div>
                    </div>

                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          Brand reputation Security
                        </div>
                      </div>
                    </div>

                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          InHouse security Team
                        </div>
                      </div>
                    </div>

                    <div className="frame-19">
                      <div className="frame-6">
                        <div className="group-14" />
                        <div className="bullet-point">
                          Proactive instead of Reactive
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <p className="our-expert-team-of">
                  Our Expert Team <br />
                  of Hacker
                </p>
              </div>

              <div className="col-2">
                <div className="div-4">
                  <div className="text-wrapper-36">Why Choose Us</div>

                  <div className="USP">
                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={databaseIcon}
                      />

                      <div className="text-wrapper-37">Data Loss Prevented</div>
                    </div>

                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={databaseIcon}
                      />

                      <div className="text-wrapper-37">Third Party Monitoring </div>
                    </div>

                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={databaseIcon}
                      />

                      <div className="text-wrapper-37">Trusted</div>
                    </div>

                    <div className="frame-20">
                      <img
                        className="trustIcon"
                        alt="trustIcon"
                        src={databaseIcon}
                      />

                      <div className="text-wrapper-37">Trusted</div>
                    </div>
                  </div>
                </div>

                <div className="certificates">

                  <img
                    className="certificateIcon"
                    alt="Screenshot"
                    src={certificates}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="our-mission">
            <div className="about-mission-component">
              <div className="col">
                <div className="div-5">
                  <img
                    className="shieldIcon"
                    alt="shieldIcon"
                    src={shield}
                  />
                </div>
                <div>
                  <p className="our-secured-brands">
                    Our Secured<br />
                  Brands
                </p>
                  <p className="comprehensive-2">
                    Retained 100% Customers since inception.  <br /> 100% perfect customer retention rate
                </p>
                </div>
              </div>

              <div className="col-2">
                <div className="certificates">
                  <img
                    className="trustedBrands"
                    alt="Screenshot"
                    src={trustedBrands}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="testimonial-section">
            <div className="title-4">
              <div className="overlap-group-8">
                <img className="line-13" alt="Line" src={line69} />

                <p className="text-wrapper-8">
                  Our Secured Client’s <br />
                  says It All
                </p>
              </div>
            </div>

            <div className="testimonial-wrap">
              <div className="testimonial-card">
                <div className="div-5">
                  <p className="comprehensive-2">
                    Comprehensive Evaluations To Identify Vulnerabilities And
                    Provide Actionable Insights For Risk Mitigation.
                  </p>
                </div>

                <div className="frame-28">
                  <img
                    className="IMG-wa"
                    alt="Img"
                    src={databaseIcon}
                  />

                  <div className="frame-29">
                    <div className="text-wrapper-11">Xyz Company</div>

                    <div className="text-wrapper-38">Co-founder</div>
                  </div>
                </div>
              </div>

              <div className="testimonial-card-2">
                <div className="div-5">
                  <p className="comprehensive-2">
                    Comprehensive Evaluations To Identify Vulnerabilities And
                    Provide Actionable Insights For Risk Mitigation.
                  </p>
                </div>

                <div className="frame-28">
                  <img
                    className="IMG-2"
                    alt="Img"
                    src={databaseIcon}
                  />

                  <div className="frame-29">
                    <div className="text-wrapper-11">Xyz Company</div>

                    <div className="text-wrapper-38">Co-founder</div>
                  </div>
                </div>
              </div>

              <div className="testimonial-card-3">
                <div className="div-5">
                  <p className="comprehensive-2">
                    Comprehensive Evaluations To Identify Vulnerabilities And
                    Provide Actionable Insights For Risk Mitigation.
                  </p>
                </div>

                <div className="frame-28">
                  <img
                    className="IMG-2"
                    alt="Img"
                    src={databaseIcon}
                  />

                  <div className="frame-29">
                    <div className="text-wrapper-11">Xyz Company</div>

                    <div className="text-wrapper-38">Co-founder</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="team-section">
            <div className="text-wrapper-39">Meet Our Team</div>

            <div className="cards">
              <div className="card-4">
                <img className="image" alt="Image" src={hardik} />

                <div className="frame-30">
                  <div className="frame-31">
                    <div className="text-wrapper-11">Hardik Rathod</div>

                    <div className="text-wrapper-38">Security Engineer</div>
                  </div>
                </div>
              </div>

              <div className="card-4">
                <img className="image" alt="Image" src={rajat} />

                <div className="frame-32">
                  <div className="frame-31">
                    <div className="text-wrapper-11">Rajat Moury</div>

                    <div className="text-wrapper-38">Founder</div>
                  </div>
                </div>
              </div>

              <div className="card-4">
                <img className="image" alt="Image" src={tushar} />

                <div className="frame-32">
                  <div className="frame-31">
                    <div className="text-wrapper-11">Tushar Kumar</div>

                    <div className="text-wrapper-38">Security Researcher</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="CTA-section">
            {/* <div className="background" /> */}
            <div className="networkLinesWrapper">  <img
              className="networkLines"
              alt="Vector"
              src={networkLines}
            /></div>

            <div className="div-2">
              <div className="text-wrapper-40">Protect Your Data Now</div>
            </div>
            <div className="div-wrapper">
              <div className="text-wrapper-6">Get Quote</div>
            </div>
            <div className="networkLinesWrapper1">  <img
              className="networkLines1"
              alt="Vector"
              src={networkLines}
            /></div>
          </div>

          <footer className="footer">
            <div className="frame-33">
              <div className="brand-logo-dk" >
                <img src={brandLogo} />
                <img src={certified} />
                <div className="rights-reserved">
                  © 2024 ApniSec. All rights reserved.
                </div>
              </div>

              <div className="frame-34">
                <div className="social-icon">
                  <img className="socialIcon" alt="Youtube" src={linkedin} />

                  <img className="line-70" alt="Line" src={line70} />

                  <img className="socialIcon" alt="Twitter" src={twitter} />

                  <img className="line-70" alt="Line" src={line70} />

                  <img className="socialIcon" alt="Twitter" src={youtube} />
                </div>

                <div className="flinks">
                  <div className="frame-35">
                    <div className="text-wrapper-45">Company</div>

                    <div className="frame-36">
                      <div className="flink-options">Home</div>

                      <div className="text-wrapper-32">Solutions</div>

                      <div className="text-wrapper-32">Process</div>

                      <div className="text-wrapper-32">Report</div>

                      <div className="text-wrapper-32">Services</div>
                    </div>
                  </div>

                  <div className="frame-35">
                    <div className="text-wrapper-45">Services</div>

                    <div className="frame-36">
                      <div className="flink-options">Dark Eye Watcher</div>

                      <div className="text-wrapper-32">Cloud Security</div>

                      <div className="text-wrapper-32">Virtual CISO</div>

                      <div className="text-wrapper-32">Red Team Assesment</div>

                      <div className="text-wrapper-32">VAPT</div>
                    </div>
                  </div>
                </div>


                <div className="privacy-policy-terms">
                  Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;Terms And Services
                </div>
              </div>
            </div>


          </footer>

        </div>
      </div>
    </div>
  );
};
